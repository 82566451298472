import { useEffect, useState } from 'react';
import { RouterProvider } from 'react-router-dom';
import { Box, Flex } from '@radix-ui/themes';
import { AppRoutes } from '@/routes';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { userApi } from './services/user';
import { workspaceApi } from './services/workspace';
import posthog from 'posthog-js';
import { Theme } from '@radix-ui/themes';
import { setThemeMode } from './redux/slices/app';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import useCaptureEvent from './hooks/useCaptureEvent';
import Intercom from '@intercom/messenger-js-sdk';

export const App = () => {
  const dispatch = useAppDispatch();
  const captureEvent = useCaptureEvent();
  const { themeMode } = useAppSelector((state) => state.appReducer);
  const { currentUser } = useAppSelector((state) => state.userReducer);
  const { currentWorkspace } = useAppSelector(
    (state) => state.workspaceReducer
  );
  const { isAuthenticated } = useAppSelector((state) => state.authReducer);

  userApi.useGetCurrentUserQuery(null!, {
    skip:
      window.location.pathname.includes('login') ||
      window.location.pathname.includes('register') ||
      window.location.pathname.includes('forgot-password') ||
      !isAuthenticated,
  });
  workspaceApi.useGetCurentUserWorkspaceInfoQuery(null!, {
    skip:
      window.location.pathname.includes('login') ||
      window.location.pathname.includes('register') ||
      window.location.pathname.includes('forgot-password') ||
      !isAuthenticated,
  });
  const isAppPro = useFeatureFlagEnabled('app-pro');
  const haveTrialProSubscription = useFeatureFlagEnabled(
    'trial-pro-subscription'
  );

  useEffect(() => {
    //testasdsadsadsada
    // identify posthog user

    if (
      currentWorkspace &&
      currentUser &&
      typeof haveTrialProSubscription !== 'undefined' &&
      typeof isAppPro !== 'undefined'
    ) {
      posthog.identify(currentUser?.email, {
        email: currentUser?.email,
        first_name: currentUser?.first_name,
        last_name: currentUser?.last_name,
        subscription_status: haveTrialProSubscription
          ? 'active'
          : currentUser?.subscription === 'paid'
          ? 'active'
          : 'inactive',
      });
      // dispatch(setShowNotificationBar(!isAppPro));
    }
  }, [currentWorkspace, currentUser, haveTrialProSubscription, isAppPro]);

  const [intercomLoadded, setIntercomLoadded] = useState<boolean>(false);

  useEffect(() => {
    if (currentUser && !intercomLoadded) {
      Intercom({
        app_id: 's2w7quxz',
        // user_id: `${currentUser.id}`, // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
        name:
          !currentUser.first_name && !currentUser.last_name
            ? `${currentUser.email}`
            : `${currentUser.first_name} ${currentUser.last_name}`, // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
        email: currentUser.email, // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
        // created_at: parseInt(currentUser.created_at as string), // IMPORTANT: Replace "user.createdAt" with the variable you use to capture the user's sign-up date in a Unix timestamp (in seconds) e.g. 1704067200
      });
      setIntercomLoadded(true);
    }
  }, [currentUser]);

  // useEffect(() => {
  //   // Function to check for the presence and visibility of .notification-bar
  //   const checkNotificationBar = (): boolean => {
  //     const element = document.querySelector('.notification-bar');
  //     if (element) {
  //       const style = window.getComputedStyle(element);
  //       return style.display !== 'none'; // Check if it's not display: none
  //     }
  //     return false;
  //   };

  //   // Callback for the MutationObserver
  //   const observerCallback: MutationCallback = (mutationsList) => {
  //     for (const mutation of mutationsList) {
  //       if (mutation.type === 'childList' || mutation.type === 'attributes') {
  //         dispatch(setShowNotificationBar(checkNotificationBar()));
  //       }
  //     }
  //   };

  //   // Create a MutationObserver
  //   const observer = new MutationObserver(observerCallback);

  //   // Observe the body for changes
  //   observer.observe(document.body, {
  //     childList: true,
  //     subtree: true,
  //     attributes: true,
  //   });

  //   // Initial check
  //   dispatch(setShowNotificationBar(checkNotificationBar()));

  //   // Cleanup
  //   return () => {
  //     observer.disconnect();
  //   };
  // }, []);

  // useEffect(() => {
  //   const waitForElement = () => {
  //     const element = document.querySelector('.notification-bar-container');

  //     if (element instanceof HTMLElement) {
  //       // Ensure element is an HTMLElement
  //       const handleClick = (event: MouseEvent) => {
  //         captureEvent('Upgrade your plan modal viewed', {
  //           source: 'Black Friday banner',
  //         });
  //         captureEvent('Black Friday banner clicked');
  //         dispatch(setIsAppPaymentPopupOpen(true));
  //       };

  //       element.addEventListener('click', handleClick);

  //       // Cleanup
  //       return () => {
  //         element.removeEventListener('click', handleClick);
  //       };
  //     } else {
  //       // Retry if the element is not available yet
  //       setTimeout(waitForElement, 100);
  //     }
  //   };

  //   waitForElement(); // Start the check

  //   // Cleanup when the component unmounts
  //   return () => {};
  // }, []);

  useEffect(() => {
    const userPrefferedThemeMode = window.localStorage.getItem('app-theme');
    const systemTheme = window.matchMedia('(prefers-color-scheme: dark)')
      .matches
      ? 'dark'
      : 'light';

    dispatch(setThemeMode(userPrefferedThemeMode || systemTheme || 'light'));
    window.localStorage.setItem(
      'app-theme',
      userPrefferedThemeMode || systemTheme || 'light'
    );

    if (
      (userPrefferedThemeMode || systemTheme) !== 'light' &&
      (userPrefferedThemeMode || systemTheme) !== 'dark'
    ) {
      dispatch(setThemeMode('light'));
      window.localStorage.setItem('app-theme', 'light');
    }
    console.log('test 2');
  }, []);

  const isMaintenanceMode = useFeatureFlagEnabled('is-maintenance-mode');

  return (
    <Theme appearance={themeMode} accentColor="blue">
      {isMaintenanceMode ? (
        <Flex
          height={'100%'}
          direction={'column'}
          align={'center'}
          justify={'center'}
        >
          <h1>We’re Under Maintenance</h1>
          <p>The app is currently undergoing maintenance.</p>
        </Flex>
      ) : (
        <Box height={'100%'} style={{ maxWidth: '2560px', margin: '0 auto' }}>
          <RouterProvider router={AppRoutes} />
        </Box>
      )}
    </Theme>
  );
};

