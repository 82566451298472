import { DailyRenderReport, reportsApi } from '@/services/reports';
import { Flex, Separator, Text } from '@radix-ui/themes';
import { ResponsiveLine } from '@nivo/line';
import Skeleton from 'react-loading-skeleton';
import './daily-renders.scss';
import { ApiKey } from '@/redux/slices/types';

interface IDailyRendersLast30Days {
  apiKey: ApiKey;
}

export const DailyRendersLast30Days = ({ apiKey }: IDailyRendersLast30Days) => {
  const dailyRendersLast30DaysData =
    reportsApi.useGetDailyRendersLast30DaysQuery(
      { apiKeyUUID: apiKey.uuid },
      {
        refetchOnMountOrArgChange: true,
      }
    );

  const calculateYAxisOffset = (dataSet: DailyRenderReport[]): number => {
    const allYValues = dataSet.flatMap((item) =>
      item.data.map((point) => parseInt(point.y))
    );

    const maxValue = Math.max(...allYValues);

    const charCount = maxValue.toString().length;

    if (charCount < 3) {
      return 35;
    } else if (charCount < 5) {
      return 45;
    } else if (charCount < 6) {
      return 50;
    } else {
      return 55;
    }
  };

  return (
    <Flex
      direction={'column'}
      width={'100%'}
      py={'2'}
      height={'40vh'}
      overflow={'hidden'}
    >
      {dailyRendersLast30DaysData.isLoading && (
        <Skeleton width={'100%'} height={'40vh'} />
      )}
      {dailyRendersLast30DaysData.isSuccess && (
        <ResponsiveLine
          margin={{ top: 40, right: 5, bottom: 70, left: 65 }}
          data={dailyRendersLast30DaysData.data?.data}
          xScale={{ type: 'band' }}
          yScale={{
            type: 'linear',
            nice: true,
          }}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: -45,
            legend: 'date',
            legendOffset: 55,
            legendPosition: 'middle',
            truncateTickAt: 0,
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'renders',
            legendOffset: -calculateYAxisOffset(
              dailyRendersLast30DaysData.data.data
            ),
            legendPosition: 'middle',
            truncateTickAt: 0,
          }}
          pointSize={1}
          pointBorderWidth={7}
          pointBorderColor={'var(--blue-9)'}
          colors={['var(--blue-9)']}
          enableTouchCrosshair={true}
          useMesh={true}
          theme={{
            text: { fill: 'var(--slate-12)' },
            grid: { line: { stroke: 'var(--slate-6)' } },
            tooltip: {
              container: {
                background: 'var(--slate-2)',
              },
            },
          }}
          tooltip={(props) => (
            <Flex direction={'column'} className="point-tooltip">
              <Text size={'2'} className="value">
                {props.point.data.y as string}
              </Text>
            </Flex>
          )}
        />
      )}
    </Flex>
  );
};
